var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('SeasonInput',{on:{"change":_vm.init_component},model:{value:(_vm.seasonSelected),callback:function ($$v) {_vm.seasonSelected=$$v},expression:"seasonSelected"}}),_c('e-select',{staticClass:"primary mt-2",attrs:{"id":"entities","track-by":"tiers_id","label":"tiers_rs","options":_vm.entities,"searchable":true,"show-labels":false,"allow-empty":false,"placeholder":_vm.placeholder_entity},on:{"input":function($event){return _vm.changeEntity()}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.tiers_rs)+" ("+_vm._s(option.tiers_stallion_number || _vm.$t('monte.stallion_number_not_set'))+")")]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.tiers_rs)+" ("+_vm._s(option.tiers_stallion_number || _vm.$t('monte.stallion_number_not_set'))+")")]}}]),model:{value:(_vm.entitySelected),callback:function ($$v) {_vm.entitySelected=$$v},expression:"entitySelected"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2),_c('CustomTable',{attrs:{"id_table":"declaration_sanitaire","items":_vm.sanitaire,"busy":_vm.table_busy,"primaryKey":"seasonmarestallion_dps","rawColumns":_vm.rawColumns,"hrefsRoutes":_vm.config_table_hrefs,"externSlotColumns":_vm.extern_slot_columns},on:{"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(grippe_label)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{class:data.grippe_class},[_vm._v(_vm._s(data.grippe_label))]),_vm._v(" "),(data.grippe && data.grippe.actes_send)?_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","pill":"","variant":"secondary btn-min-with"},on:{"click":function($event){return _vm.onSubmit(data.seasonmarestallion_id, 'grippe')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync']}})],1):_vm._e()],1)]}},{key:"custom-slot-cell(rhino_label)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{class:data.rhino_class},[_vm._v(_vm._s(data.rhino_label))]),_vm._v(" "),(data.rhino && data.rhino.actes_send)?_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","pill":"","variant":"secondary btn-min-with"},on:{"click":function($event){return _vm.onSubmit(data.seasonmarestallion_id, 'rhino')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync']}})],1):_vm._e()],1)]}},{key:"custom-slot-cell(ave_label)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{class:data.ave_class},[_vm._v(_vm._s(data.ave_label))]),_vm._v(" "),(data.ave && data.ave.actes_send)?_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","pill":"","variant":"secondary btn-min-with"},on:{"click":function($event){return _vm.onSubmit(data.seasonmarestallion_id, 'ave')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync']}})],1):_vm._e()],1)]}},{key:"custom-slot-cell(ave_prev_label)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{class:data.ave_prev_class},[_vm._v(_vm._s(data.ave_prev_label))]),_vm._v(" "),(data.ave_prev && data.ave_prev.actes_send)?_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","pill":"","variant":"secondary btn-min-with"},on:{"click":function($event){return _vm.onSubmit(data.seasonmarestallion_id, 'ave_prev')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync']}})],1):_vm._e()],1)]}},{key:"custom-slot-cell(mec_tayl_label)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{class:data.mec_tayl_class},[_vm._v(_vm._s(data.mec_tayl_label))]),_vm._v(" "),(data.mec_tayl && data.mec_tayl.actes_send)?_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","pill":"","variant":"secondary btn-min-with"},on:{"click":function($event){return _vm.onSubmit(data.seasonmarestallion_id, 'mec')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync']}})],1):_vm._e()],1)]}},{key:"custom-slot-cell(mec_klebs_label)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{class:data.mec_klebs_class},[_vm._v(_vm._s(data.mec_klebs_label))]),_vm._v(" "),(data.mec_klebs && data.mec_klebs.actes_send)?_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","pill":"","variant":"secondary btn-min-with"},on:{"click":function($event){return _vm.onSubmit(data.seasonmarestallion_id, 'mec_klebs')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync']}})],1):_vm._e()],1)]}},{key:"custom-slot-cell(mec_pseudo_label)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{class:data.mec_pseudo_class},[_vm._v(_vm._s(data.mec_pseudo_label))]),_vm._v(" "),(data.mec_pseudo && data.mec_pseudo.actes_send)?_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","pill":"","variant":"secondary btn-min-with"},on:{"click":function($event){return _vm.onSubmit(data.seasonmarestallion_id, 'mec_pseudo')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync']}})],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }