import Constants from '../../config.local.js'

var DPSmixin = {
    methods: {
    	loadCarnetSaillie: async function(tiers_id, season_id) {
			const params = {tiers_id, season_id}
			const url = this.constructRoute(Constants.DPS_CARNET_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("DPSmixin::loadCarnetSaillie", url)
			.catch(error => {
				console.error("DPSmixin::loadCarnetSaillie => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		importCarnetSaillie: async function(tiers_id, season_id) {
			const params = {tiers_id, season_id}
			const url = this.constructRoute(Constants.DPS_IMPORT_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("DPSmixin::importCarnetSaillie", url)
			.catch(error => {
				console.error("DPSmixin::importCarnetSaillie => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		prepareCarnetSaillie: async function(dps_id, stallion_mare_ids) {
			const params = {dps_id:dps_id, stallion_mare_ids:stallion_mare_ids.join(',')}
			const url = this.constructRoute(Constants.DPS_PREPARE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("DPSmixin::prepareCarnetSaillie", url)
			.catch(error => {
				console.error("DPSmixin::prepareCarnetSaillie => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		getStallionMareSaillie: async function(season_id, dps_id) {
			const params = {season_id, dps_id}
			const url = this.constructRoute(Constants.DPS_SAILLIE_INFO_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("DPSmixin::getStallionMareSaillie", url)
			.catch(error => {
				console.error("DPSmixin::getStallionMareSaillie => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		envoieCarnetSaillie: async function(dps_id) {
			const params = {dps_id:dps_id}
			const url = this.constructRoute(Constants.DPS_ENVOIE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("DPSmixin::envoieCarnetSaillie", url)
			.catch(error => {
				console.error("DPSmixin::envoieCarnetSaillie => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		// SANITAIRE

		loadActeSanitaire: async function(tiers_id, season_id) {
			const params = {tiers_id, season_id}
			const url = this.constructRoute(Constants.ACTE_SANITAIRE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("DPSmixin::loadActeSanitaire", url)
			.catch(error => {
				console.error("DPSmixin::loadActeSanitaire => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		sendActeSanitaire: async function(tiers_id, season_id, couples) {
			const params = {tiers_id, season_id}
			const url = this.constructRoute(Constants.ACTE_SANITAIRE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_post_api("AccountingMixin::sendActeSanitaire", url, {couples}, false)
			.catch(e => {
				console.error("DPSmixin::sendActeSanitaire", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
		},

		resetActeSanitaire: async function(tiers_id, season_id, sms_id, maladie) {
			const params = {tiers_id, season_id, sms_id, maladie}
			const url = this.constructRoute(Constants.ACTE_SANITAIRE_REST_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_get_api("AccountingMixin::resetActeSanitaire", url)
			.catch(e => {
				console.error("DPSmixin::resetActeSanitaire", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
		},

		loadActeSanitaireMare: async function(tiers_id, season_id, seasonmare_id) {
			const params = {tiers_id, season_id, seasonmare_id}
			const url = this.constructRoute(Constants.ACTE_SANITAIRE_JUMENT_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("DPSmixin::loadActeSanitaireMare", url)
			.catch(error => {
				console.error("DPSmixin::loadActeSanitaireMare => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		loadContractToImportDNAS: async function(season_id, stallion_num) {
			const url = this.constructRoute(Constants.CONTRACT_IMPORT_DN_AS_URL, {season_id, stallion_num}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("DPSmixin::loadContractToImportDNAS", url)
			.catch(error => {
				console.error("DPSmixin::loadContractToImportDNAS => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		importContractDNAS: async function(season_id, stallion_num, contracts) {
			const url = this.constructRoute(Constants.CONTRACT_IMPORT_DN_AS_URL, {season_id, stallion_num}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_post_api("AccountingMixin::importContractDNAS", url, {contracts}, false)
			.catch(e => {
				console.error("DPSmixin::importContractDNAS", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
		},
    }
}


export default DPSmixin