<template>
	<div class="box">
		<SeasonInput v-model="seasonSelected" @change="init_component" />

		<e-select
			v-model="entitySelected"
			id="entities"
			track-by="tiers_id"
			label="tiers_rs"
			class="primary mt-2"
			:options="entities"
			:searchable="true"
			:show-labels="false"
			:allow-empty="false"
			:placeholder="placeholder_entity"
			@input="changeEntity()"
		>
			<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_stallion_number || $t('monte.stallion_number_not_set') }})</template>
			<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_stallion_number || $t('monte.stallion_number_not_set') }})</template>
			<template slot="noOptions">{{ $t('global.list_empty') }}</template>
		</e-select>

		<CustomTable
			id_table="declaration_sanitaire"
			:items="sanitaire"
			:busy.sync="table_busy"
			primaryKey="seasonmarestallion_dps"
			:rawColumns="rawColumns"
			:hrefsRoutes="config_table_hrefs"
			:externSlotColumns="extern_slot_columns"
		>
			<template v-slot:custom-slot-cell(grippe_label)="{ data }">
				<div class="d-flex align-items-center">
					<span :class="data.grippe_class">{{ data.grippe_label }}</span> <b-button class="ml-2" v-if="data.grippe && data.grippe.actes_send" size="sm" pill variant="secondary btn-min-with" @click="onSubmit(data.seasonmarestallion_id, 'grippe')"><font-awesome-icon :icon="['fal', 'sync']"></font-awesome-icon></b-button>
				</div>
			</template>

			<template v-slot:custom-slot-cell(rhino_label)="{ data }">
				<div class="d-flex align-items-center">
					<span :class="data.rhino_class">{{ data.rhino_label }}</span> <b-button class="ml-2" v-if="data.rhino && data.rhino.actes_send" size="sm" pill variant="secondary btn-min-with" @click="onSubmit(data.seasonmarestallion_id, 'rhino')"><font-awesome-icon :icon="['fal', 'sync']"></font-awesome-icon></b-button>
				</div>
			</template>

			<template v-slot:custom-slot-cell(ave_label)="{ data }">
				<div class="d-flex align-items-center">
					<span :class="data.ave_class">{{ data.ave_label }}</span> <b-button class="ml-2" v-if="data.ave && data.ave.actes_send" size="sm" pill variant="secondary btn-min-with" @click="onSubmit(data.seasonmarestallion_id, 'ave')"><font-awesome-icon :icon="['fal', 'sync']"></font-awesome-icon></b-button>
				</div>
			</template>

			<template v-slot:custom-slot-cell(ave_prev_label)="{ data }">
				<div class="d-flex align-items-center">
					<span :class="data.ave_prev_class">{{ data.ave_prev_label }}</span> <b-button class="ml-2" v-if="data.ave_prev && data.ave_prev.actes_send" size="sm" pill variant="secondary btn-min-with" @click="onSubmit(data.seasonmarestallion_id, 'ave_prev')"><font-awesome-icon :icon="['fal', 'sync']"></font-awesome-icon></b-button>
				</div>
			</template>

			<template v-slot:custom-slot-cell(mec_tayl_label)="{ data }">
	        	<div class="d-flex align-items-center">
	        		<span :class="data.mec_tayl_class">{{ data.mec_tayl_label }}</span> <b-button class="ml-2" v-if="data.mec_tayl && data.mec_tayl.actes_send" size="sm" pill variant="secondary btn-min-with" @click="onSubmit(data.seasonmarestallion_id, 'mec')"><font-awesome-icon :icon="['fal', 'sync']"></font-awesome-icon></b-button>
	        	</div>
			</template>

	        <template v-slot:custom-slot-cell(mec_klebs_label)="{ data }">
	        	<div class="d-flex align-items-center">
	        		<span :class="data.mec_klebs_class">{{ data.mec_klebs_label }}</span> <b-button class="ml-2" v-if="data.mec_klebs && data.mec_klebs.actes_send" size="sm" pill variant="secondary btn-min-with" @click="onSubmit(data.seasonmarestallion_id, 'mec_klebs')"><font-awesome-icon :icon="['fal', 'sync']"></font-awesome-icon></b-button>
	        	</div>
			</template>

	        <template v-slot:custom-slot-cell(mec_pseudo_label)="{ data }">
	        	<div class="d-flex align-items-center">
	        		<span :class="data.mec_pseudo_class">{{ data.mec_pseudo_label }}</span> <b-button class="ml-2" v-if="data.mec_pseudo && data.mec_pseudo.actes_send" size="sm" pill variant="secondary btn-min-with" @click="onSubmit(data.seasonmarestallion_id, 'mec_pseudo')"><font-awesome-icon :icon="['fal', 'sync']"></font-awesome-icon></b-button>
	        	</div>
			</template>
		</CustomTable>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from "EventBus";
    import DPS from "@/mixins/DPS.js"
	import Gynecologie from "@/mixins/Gynecologie.js"
    import Tiers from "@/mixins/Tiers.js"
    import Navigation from "@/mixins/Navigation.js"

	export default {
		name: 'EnvoiJument',
		mixins: [DPS, Gynecologie, Tiers, Navigation],
		props: ['horse'],
		data () {
			return {
                entities: [],
                entitySelected: null,
				seasonSelected: null,
				seasonMare: null,
				sanitaire: [],
				placeholder_entity: this.getTrad('monte.placeholder_entity'),
				table_busy: false,
				rawColumns: ['grippe_label', 'rhino_label', 'ave_label', 'ave_prev_label', 'mec_tayl_label', 'error'],
				extern_slot_columns: ['grippe_label', 'rhino_label', 'ave_label', 'ave_prev_label', 'mec_tayl_label', 'mec_klebs_label', 'mec_pseudo_label'],
				config_table_hrefs: {
					'mare_name': {
						routeUniqueName: 'horseFicheMare',
						routeName: 'horseFiche',
						params: {
							horse_id: 'mare_id'
						}
					},
					'stallion_name': {
						routeUniqueName: 'horseFicheStallion',
						routeName: 'horseFiche',
						params: {
							horse_id: 'stallion_id'
						}
					},
				},
				events_tab: {
                    'TableAction::goToSendSanitaire': this.sendSanitaire,
                }
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.seasonSelected) {
					this.seasonMare = await this.loadSeasonMareStallionBySeasonHorse(this.horse.horse_id, this.seasonSelected.id)
				}
                const entities = await this.loadTiersEntity()
                this.entities = entities.filter(entity => entity.tiers_stallion_number)
                if(this.entities.length > 0) {
	                this.entitySelected = this.entities[0]
	                this.changeEntity()
	            }
			},

			changeEntity() {
				this.loadSanitaire(this.entitySelected.tiers_id)
			},

			async loadSanitaire(tiers_id) {
				if(!this.seasonMare) return false

				this.table_busy = true
				const sanitaire = await this.loadActeSanitaireMare(tiers_id, this.seasonSelected.id, this.seasonMare[0].seasonmare_id)
				this.sanitaire = sanitaire
				if(this.sanitaire) {
					this.sanitaire.forEach(acte => {
						const date_grippe = acte.grippe ? this.formatDate(acte.grippe.actes_date) : 'N/A'
						acte.grippe_label = date_grippe
						acte.grippe_class = acte.customClasses.grippe_label

						const date_rhino = acte.rhino ? this.formatDate(acte.rhino.actes_date) : 'N/A'
						acte.rhino_label = date_rhino
						acte.rhino_class = acte.customClasses.rhino_label

						const date_ave = acte.ave ? this.formatDate(acte.ave.actes_date) : 'N/A'
						const ave = acte.ave && acte.ave.result ? " - " + acte.ave.result.reponse_label : ''
						acte.ave_label =  date_ave + ave
						let errors_ave = []
						if(acte.error.includes('ave_labo')) errors_ave.push('Laboratoire')
						if(acte.error.includes('ave_matrice')) errors_ave.push('Site de prélèvement')
						if(acte.error.includes('ave_analyse')) errors_ave.push('Analyse')
						if(errors_ave.length > 0)
							acte.ave_label += " (" + errors_ave.join(' - ') + ")"
						acte.ave_class = acte.customClasses.ave_label

						const date_ave_prev = acte.ave_prev ? this.formatDate(acte.ave_prev.actes_date) : 'N/A'
						const ave_prev = acte.ave_prev && acte.ave_prev.result ? " - " + acte.ave_prev.result.reponse_label : ''
						acte.ave_prev_label =  date_ave_prev + ave_prev
						let errors_ave_prev = []
						if(acte.error.includes('ave_prev_labo')) errors_ave_prev.push('Laboratoire')
						if(acte.error.includes('ave_prev_matrice')) errors_ave_prev.push('Site de prélèvement')
						if(acte.error.includes('ave_prev_analyse')) errors_ave_prev.push('Analyse')
						if(errors_ave_prev.length > 0)
							acte.ave_prev_label += " (" + errors_ave_prev.join(' - ') + ")"
						acte.ave_prev_class = acte.customClasses.ave_prev_label

						const date_mec_tayl = acte.mec_tayl ? this.formatDate(acte.mec_tayl.actes_date) : 'N/A'
						const mec_tayl = acte.mec_tayl && acte.mec_tayl.result ? " - " + acte.mec_tayl.result.reponse_label : ''
						acte.mec_tayl_label =  date_mec_tayl + mec_tayl
						let errors_mec_tayl = []
						if(acte.error.includes('mec_labo')) errors_mec_tayl.push('Laboratoire')
						if(acte.error.includes('mec_matrice')) errors_mec_tayl.push('Site de prélèvement')
						if(acte.error.includes('mec_analyse')) errors_mec_tayl.push('Analyse')
						if(errors_mec_tayl.length > 0)
							acte.mec_tayl_label += " (" + errors_mec_tayl.join(' - ') + ")"
						acte.mec_tayl_class = acte.customClasses.mec_tayl_label

						const date_mec_klebs = acte.mec_klebs ? this.formatDate(acte.mec_klebs.actes_date) : 'N/A'
						const mec_klebs = acte.mec_klebs && acte.mec_klebs.result ? " - " + acte.mec_klebs.result.reponse_label : ''
						acte.mec_klebs_label =  date_mec_klebs + mec_klebs
						let errors_mec_klebs = []
						if(acte.error.includes('mec_klebs_labo')) errors_mec_klebs.push('Laboratoire')
						if(acte.error.includes('mec_klebs_matrice')) errors_mec_klebs.push('Site de prélèvement')
						if(acte.error.includes('mec_klebs_analyse')) errors_mec_klebs.push('Analyse')
						if(errors_mec_klebs.length > 0)
							acte.mec_klebs_label += " (" + errors_mec_klebs.join(' - ') + ")"
						acte.mec_klebs_class = acte.customClasses.mec_klebs_label

						const date_mec_pseudo = acte.mec_pseudo ? this.formatDate(acte.mec_pseudo.actes_date) : 'N/A'
						const mec_pseudo = acte.mec_pseudo && acte.mec_pseudo.result ? " - " + acte.mec_pseudo.result.reponse_label : ''
						acte.mec_pseudo_label =  date_mec_pseudo + mec_pseudo
						let errors_mec_pseudo = []
						if(acte.error.includes('mec_pseudo_labo')) errors_mec_pseudo.push('Laboratoire')
						if(acte.error.includes('mec_pseudo_matrice')) errors_mec_pseudo.push('Site de prélèvement')
						if(acte.error.includes('mec_pseudo_analyse')) errors_mec_pseudo.push('Analyse')
						if(errors_mec_pseudo.length > 0)
							acte.mec_pseudo_label += " (" + errors_mec_pseudo.join(' - ') + ")"
						acte.mec_pseudo_class = acte.customClasses.mec_pseudo_label

						acte.error = acte.error.length > 0 ? this.getTrad('global.oui') : this.getTrad('global.non')
					})
				}
				this.table_busy = false
				EventBus.$emit('TableAction::stopSpin')
			},

			async sendSanitaire(couples) {
				const error = couples.filter(couple => couple.error == "Oui")
				if(error.length > 0) {
					this.failureToast("monte.sanitaire_only_error")
					return false
				}

				const seasonmarestallion = couples.map(couple => {
					let res = {
						sanitaire_seasonmarestallion: couple.seasonmarestallion_id,
						mare_id: couple.mare_id
					}

					if(couple.grippe && couple.grippe.actes_send == 0) {
						res.sanitaire_grippe_date = couple.grippe ? couple.grippe.actes_date : null
						res.sanitaire_grippe_acte = couple.grippe ? couple.grippe.actes_id : null
					}

					if(couple.rhino && couple.rhino.actes_send == 0) {
						res.sanitaire_rhino_date = couple.rhino ? couple.rhino.actes_date : null
						res.sanitaire_rhino_acte = couple.rhino ? couple.rhino.actes_id : null
					}

					if(couple.ave && couple.ave.actes_send == 0) {
						res.sanitaire_ave_date = couple.ave ? couple.ave.actes_date : null
						res.sanitaire_ave_acte = couple.ave ? couple.ave.actes_id : null
						res.sanitaire_ave_result = couple.ave ? couple.ave.result : null
					}

					if(couple.ave_prev && couple.ave_prev.actes_send == 0) {
						res.sanitaire_aveprev_date = couple.ave_prev ? couple.ave_prev.actes_date : null
						res.sanitaire_aveprev_acte = couple.ave_prev ? couple.ave_prev.actes_id : null
						res.sanitaire_aveprev_result = couple.ave_prev ? couple.ave_prev.result : null
					}

					if(couple.mec_tayl && couple.mec_tayl.actes_send == 0) {
						res.sanitaire_mec_date = couple.mec_tayl ? couple.mec_tayl.actes_date : null
						res.sanitaire_mec_acte = couple.mec_tayl ? couple.mec_tayl.actes_id : null
						res.sanitaire_mec_result = couple.mec_tayl ? couple.mec_tayl.result : null
					}

					if(couple.mec_klebs && couple.mec_klebs.actes_send == 0) {
						res.sanitaire_mec_klebs_date = couple.mec_klebs ? couple.mec_klebs.actes_date : null
						res.sanitaire_mec_klebs_acte = couple.mec_klebs ? couple.mec_klebs.actes_id : null
						res.sanitaire_mec_klebs_result = couple.mec_klebs ? couple.mec_klebs.result : null
					}

					if(couple.mec_pseudo && couple.mec_pseudo.actes_send == 0) {
						res.sanitaire_mec_pseudo_date = couple.mec_pseudo ? couple.mec_pseudo.actes_date : null
						res.sanitaire_mec_pseudo_acte = couple.mec_pseudo ? couple.mec_pseudo.actes_id : null
						res.sanitaire_mec_pseudo_result = couple.mec_pseudo ? couple.mec_pseudo.result : null
					}

					return res
				})

				await this.sendActeSanitaire(this.entitySelected.tiers_id, this.seasonSelected.id, seasonmarestallion)
				await this.loadSanitaire(this.entitySelected.tiers_id, this.seasonSelected.id)
			},

			async onSubmit(seasonmarestallion, maladie) {
				await this.resetActeSanitaire(this.entitySelected.tiers_id, this.seasonSelected.id, seasonmarestallion, maladie)
				await this.loadSanitaire(this.entitySelected.tiers_id, this.seasonSelected.id)
			}
		},

		components:{
            HeaderTab: () => import('@/components/HeaderTab'),
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'), 
            ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
			SeasonInput : () => import('@/components/Inputs/SeasonInput')
		}
	}

</script>